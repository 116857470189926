import React from "react";

const AddressComponent = () => {
    return (
        <div style={{ textAlign: "center" }}>
            <p>
                <strong>Diesel Down</strong> <br />
                2274 Old Pigeon Roost Rd. <br />
                Byhalia, MS 38611 <br />
            </p>
        </div>
    );
};

export default AddressComponent;
